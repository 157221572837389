const redirects = {
   'url1' : { landing_page : 'https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=edr1', name: "for-foretag" },
   'url2' : { landing_page : 'https://hundstallet.se/radda-ensamma-och-vanvardade-mot-spetsganget/?utm_source=reaktion&utm_medium=email&utm_campaign=edr1', name: "radda-ensamma-och-vanvardade-mot-spetsganget" },
   'url3' : { landing_page : 'https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=edr1', name: "for-foretag-2" },

   'url4' : { landing_page: "https://hundstallet.se/", name: "Logo" },
   'url5' : { landing_page: "https://www.facebook.com/Hundstallet/", name: "Facebook" },
   'url6' : { landing_page: "https://www.linkedin.com/company/hundstallet/", name: "LinkedIN" },
   'url7' : { landing_page: "https://www.instagram.com/hundstallet/", name: "Instragram" },
   'url8' : { landing_page : 'https://www.tiktok.com/@hundstalletoffical', name: "tiktok" },
   'url9' : { landing_page: "https://www.youtube.com/c/hundstalletshf", name: "YouTube" },

   'url10' : { landing_page: "https://hundstallet.se/utmanande-arbete-att-lara-spetsganget-allt-fran-grunden/?utm_source=reaktion&utm_medium=email&utm_campaign=edr3", name: "utmanande-arbete-att-lara-spetsganget-allt-fran-grunden" },
   'url11' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=edr3", name: "for-foretag" },

   'url12' : { landing_page: "https://hundstallet.se/fran-traning-och-aktivering-till-att-hitta-hundarnas-framtida-hem/?utm_source=reaktion&utm_medium=email&utm_campaign=edr4", name: "upplev-spetsgängets-dagar" },
   'url13' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=edr4", name: "for-foretag" },
   
   'url14' : { landing_page: "https://hundstallet.se/tardrypande-mote-nar-spetsganget-antligen-far-nytt-for-alltid-hem/?utm_source=reaktion&utm_medium=email&utm_campaign=edr5", name: "ardrypande-mote-nar-spetsganget-antligen-far-nytt-for-alltid-hem" },
   'url15' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=edr5", name: "for-foretag" },
   

   'url16' : { landing_page: "https://hundstallet.se/kampanj/foretagsvan-orkelljunga/?utm_source=reaktion&utm_medium=email&utm_campaign=orkelljunga", name: "foretagsvan-orkelljunga-cta1" },
   'url17' : { landing_page: "https://hundstallet.se/kampanj/foretagsvan-orkelljunga/?utm_source=reaktion&utm_medium=email&utm_campaign=orkelljunga", name: "foretagsvan-orkelljunga-cta2" },

   'url18' : { landing_page: "https://hundstallet.se/kampanj/foretagsvan-orkelljunga/?utm_source=reaktion&utm_medium=email&utm_campaign=orkelljunga_repeat", name: "foretagsvan-orkelljunga-repeat-cta1" },
   'url19' : { landing_page: "https://hundstallet.se/kampanj/foretagsvan-orkelljunga/?utm_source=reaktion&utm_medium=email&utm_campaign=orkelljunga_repeat", name: "foretagsvan-orkelljunga-repeat-cta2" },
   
   'url20' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=tyra", name: "for-foretag-tyra-cta1" },
   'url21' : { landing_page: "https://hundstallet.se/folj-tyras-resa/?utm_source=reaktion&utm_medium=email&utm_campaign=tyra", name: "folj-tyras-resa" },
   'url22' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=tyra", name: "for-foretag-tyra-cta2" },


   'url23' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=tyra-repeat", name: "for-foretag-tyra-cta1" },
   'url24' : { landing_page: "https://hundstallet.se/folj-tyras-resa/?utm_source=reaktion&utm_medium=email&utm_campaign=tyra-repeat", name: "folj-tyras-resa" },
   'url25' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=tyra-repeat", name: "for-foretag-tyra-cta2" },
   'url26' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=tyra-repeat", name: "for-foretag-tyra-cta3" },
   'url27' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=tyra-repeat", name: "for-foretag-tyra-cta4" },

   'url28' : { landing_page: "https://hundstallet.se/tyras-liv/?utm_source=reaktion&utm_medium=email&utm_campaign=edr8-tyra", name: "tyras-liv-cta" },
   'url29' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=edr8-tyra", name: "for-foretag-tyra-cta" },
   'url30' : { landing_page: "https://hundstallet.se/tak-over-huvudet/?utm_source=reaktion&utm_medium=email&utm_campaign=edr8-tyra", name: "tak-over-huvudet-cta" },
   'url31' : { landing_page: "https://hundstallet.se/tillbaka-pa-tassarna/?utm_source=reaktion&utm_medium=email&utm_campaign=edr8-tyra", name: "tillbaka-pa-tassarna-cta" },
   'url32' : { landing_page: "https://hundstallet.se/trygg-i-sig-sjalv-paketet/?utm_source=reaktion&utm_medium=email&utm_campaign=edr8-tyra", name: "trygg-i-sig-sjalv-paketet-cta" },
   'url33' : { landing_page: "https://hundstallet.se/for-alltid-hem-paketet/?utm_source=reaktion&utm_medium=email&utm_campaign=edr8-tyra", name: "for-alltid-hem-paketet-cta" },

   'url34' : { landing_page: "https://hundstallet.se/sagor-lugnar/?utm_source=reaktion&utm_medium=email&utm_campaign=edr9-tyra", name: "sagor-lugnar-cta" },
   'url35' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=edr9-tyra", name: "for-foretag-tyra-cta" },
   'url36' : { landing_page: "https://hundstallet.se/tak-over-huvudet/?utm_source=reaktion&utm_medium=email&utm_campaign=edr9-tyra", name: "tak-over-huvudet-cta" },
   'url37' : { landing_page: "https://hundstallet.se/tillbaka-pa-tassarna/?utm_source=reaktion&utm_medium=email&utm_campaign=edr9-tyra", name: "tillbaka-pa-tassarna-cta" },
   'url38' : { landing_page: "https://hundstallet.se/trygg-i-sig-sjalv-paketet/?utm_source=reaktion&utm_medium=email&utm_campaign=edr9-tyra", name: "trygg-i-sig-sjalv-paketet-cta" },
   'url39' : { landing_page: "https://hundstallet.se/for-alltid-hem-paketet/?utm_source=reaktion&utm_medium=email&utm_campaign=edr9-tyra", name: "for-alltid-hem-paketet-cta" },

   'url40' : { landing_page: "https://hundstallet.se/tyra-lamnar/?utm_source=reaktion&utm_medium=email&utm_campaign=edr10-tyra", name: "tyra-lamnar" },
   'url41' : { landing_page: "https://hundstallet.se/for-foretag/?utm_source=reaktion&utm_medium=email&utm_campaign=edr10-tyra", name: "for-foretag-tyra-cta" },
   'url42' : { landing_page: "https://hundstallet.se/tak-over-huvudet/?utm_source=reaktion&utm_medium=email&utm_campaign=edr10-tyra", name: "tak-over-huvudet-cta" },
   'url43' : { landing_page: "https://hundstallet.se/tillbaka-pa-tassarna/?utm_source=reaktion&utm_medium=email&utm_campaign=edr10-tyra", name: "tillbaka-pa-tassarna-cta" },
   'url44' : { landing_page: "https://hundstallet.se/trygg-i-sig-sjalv-paketet/?utm_source=reaktion&utm_medium=email&utm_campaign=edr10-tyra", name: "trygg-i-sig-sjalv-paketet-cta" },
   'url45' : { landing_page: "https://hundstallet.se/for-alltid-hem-paketet/?utm_source=reaktion&utm_medium=email&utm_campaign=edr10-tyra", name: "for-alltid-hem-paketet-cta" },


   'url46' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr1-julkampanj", name: "edr1-julkampanj-toppbild" },
   'url47' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr1-julkampanj", name: "edr1-julkampanj-lastbilbild" },
   'url48' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr1-julkampanj", name: "edr1-julkampanj-lasmer" },
   'url49' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr1-julkampanj", name: "edr1-julkampanj-gava" },

   'url50' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr2-julkampanj", name: "edr2-julkampanj" },
   
   'url50' : { landing_page: "https://hundstallet.se/om-oss/?utm_source=reaktion&utm_medium=email&utm_campaign=edr1-julkampanj-nymalgrupp", name: "edr1-Hundens-basta-van-nymalgrupp" },
   'url51' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr1-julkampanj-nymalgrupp", name: "edr1-lastbil-nymalgrupp" },
   
   'url52' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr3-julkampanj", name: "edr3-hundarna-far-mitt-stod" },
   'url53' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr3-julkampanj", name: "edr3-saker-resa-till-tryggheten" },
   'url54' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr3-julkampanj", name: "edr3-gavomaterial-som-tack" },
   'url55' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr3-julkampanj", name: "edr3-hur-mycket-vill-du-stodja-med" },


   'url56' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr4-julkampanj", name: "edr4-glom-inte-bort-de-utsatta-hundarna" },
   'url57' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr4-julkampanj", name: "edr4-tryggare-resa-for-fler-hundar" },
   'url58' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr4-julkampanj", name: "edr4-hur-mycket-vill-du-stodja-med" },
   'url59' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr4-julkampanj", name: "edr4-gavomaterial-som-tack" },


   'url60' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr5-julkampanj", name: "edr5-glom-inte-bort-de-utsatta-hundarna" },
   'url61' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr5-julkampanj", name: "edr5-tryggare-resa-for-fler-hundar" },
   'url62' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr5-julkampanj", name: "edr5-hur-mycket-vill-du-stodja-med" },
   'url63' : { landing_page: "https://hundstallet.se/kampanj/lastbil/?utm_source=reaktion&utm_medium=email&utm_campaign=edr5-julkampanj", name: "edr5-gavomaterial-som-tack" },

};

export default redirects