<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import "url-search-params-polyfill";

export default {
  name: "App",
  mounted() {

    /**
     * Get all params from url
     */
    let url = new URLSearchParams(window.location.search);
    /**
     * Initilaze params
     */
    let uid = null
    let redirect_id = 'url1';
    let redirect_url = '';

    /**
     * Save uid in local storage if passed in url
     */
    if (url.get("uid")) {
      localStorage.setItem("ra_uid", url.get("uid"));   
      uid = url.get("uid")
    }

    /**
     * Because of limits of Azure websites we need to create here fake router
     * which base on params passed in url.
     *
     * First we check ?act=<action> and route to the right component
     * !OBS, link to component must contain all params passed in orignial url (but not act=)
     * 
     * Example urls
     * 
     * redirect - https://<site.domain>/?act=redirect&redirect_id=url&uid=<user_id>
     * unregister - https://<site.domain>/?act=unregister&uid=<user_id>
     * 
     */

    if ( url.get('act') == 'unregister' ) {
      /**
       * Unregister 
       */
      if (uid == null) {     
      
        this.$router.push("/unregister");

      }
      else {
          
        this.$router.push(`/unregister?uid=${url.get('uid')}`);

      }
      
    } else if ( url.get('act') == 'redirect' ) {
      /**
       * Redirects
       */
      if (url.get('redirect_id')) {
        redirect_id = url.get('redirect_id')
      }

      if (uid != null ) {
        redirect_url = `/redirect?redirect_id=${redirect_id}&uid=${uid}`
      } else {
        redirect_url = `/redirect?redirect_id=${redirect_id}`
      }

      /**
       * Go to redirect componenet
       */
      this.$router.push(redirect_url)      

    } else {
      let backup_url = null;
      /**
       * Backup router for url without 'act' param
       */
      if ( url.get('redirect_id')) {
        redirect_id = url.get('redirect_id')
        uid = url.get('uid') || false;
        backup_url = `/redirect?redirect_id=${redirect_id}&uid=${uid}`
      } else {
        /**
         * Unregister request
         */
        if (uid != null) {
          backup_url = `/unregister?uid=${uid}`
        } else {
          backup_url = `/unregister`
        }
        
      }
      /**
       * Backup route
       */
      this.$router.push(backup_url);
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
