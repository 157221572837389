import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import redirects from "./redirects";
import tags from "./tags";

const app = createApp(App)

app.use(router)
app.config.globalProperties.$redirects = redirects
app.config.globalProperties.$tags = tags
app.mount('#app')

export default app

/**
 * Next, initialize Sentry in your app entry point before you initialize your root component.
 * 
 * 
 * import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://4d2f35a36bfa45709fde58d2be19dd58@o279592.ingest.sentry.io/5822859",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
 */
